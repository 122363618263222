import React from 'react';
import { motion } from 'framer-motion';
import { TypeAnimation } from 'react-type-animation';
import CountUp from 'react-countup';
import { 
  FaArrowRight, FaGithub, FaLinkedin, FaUsers, FaGraduationCap, 
  FaFileAlt, FaClock, FaLaptopCode, FaChartLine, FaBrain, FaServer 
} from 'react-icons/fa';
import { 
  SiPython, SiReact, SiNodedotjs, SiTensorflow, SiPytorch, 
  SiScikitlearn, SiDocker, SiKubernetes, SiAmazonaws, SiMongodb, 
  SiPostgresql, SiRedis 
} from 'react-icons/si';
import vibeTensorPreview from '../images/vibetensor-home.png';
import dharaLinkPreview from '../images/dharalink-home.png';

const Home = ({ setCurrentSection }) => {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const staggerContainer = {
    animate: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const techStack = [
    { icon: <SiPython />, name: 'Python' },
    { icon: <SiReact />, name: 'React' },
    { icon: <SiNodedotjs />, name: 'Node.js' },
    { icon: <SiTensorflow />, name: 'TensorFlow' },
    { icon: <SiPytorch />, name: 'PyTorch' },
    { icon: <SiScikitlearn />, name: 'Scikit-learn' },
    { icon: <SiDocker />, name: 'Docker' },
    { icon: <SiKubernetes />, name: 'Kubernetes' },
    { icon: <SiAmazonaws />, name: 'AWS' },
    { icon: <SiMongodb />, name: 'MongoDB' },
    { icon: <SiPostgresql />, name: 'PostgreSQL' },
    { icon: <SiRedis />, name: 'Redis' }
  ];

  const services = [
    {
      icon: <FaBrain className="w-8 h-8" />,
      title: "AI & Machine Learning",
      description: "Developing intelligent solutions using cutting-edge AI technologies"
    },
    {
      icon: <FaLaptopCode className="w-8 h-8" />,
      title: "Full Stack Development",
      description: "Building scalable web applications with modern technologies"
    },
    {
      icon: <FaChartLine className="w-8 h-8" />,
      title: "Data Science",
      description: "Transforming data into actionable insights"
    },
    {
      icon: <FaServer className="w-8 h-8" />,
      title: "Cloud Architecture",
      description: "Designing robust and scalable cloud solutions"
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="min-h-screen flex items-center justify-center relative">
        <div className="container mx-auto px-4 py-16">
          <motion.div 
            className="max-w-4xl mx-auto text-center"
            initial="initial"
            animate="animate"
            variants={staggerContainer}
          >
            <motion.h1 
              className="text-5xl md:text-7xl font-bold mb-6"
              variants={fadeInUp}
            >
              <span className="block mb-4 ai-gradient-text">Hi There, I'm</span>
              <span className="block ai-gradient-text">Dubasi Pavan Kumar</span>
            </motion.h1>

            <motion.p 
              className="text-xl md:text-2xl text-gray-600 dark:text-gray-300 mb-12 leading-relaxed"
              variants={fadeInUp}
            >
              Driving{' '}
              <span className="font-bold text-purple-600 dark:text-purple-400">
                unbiased AI
              </span>
              {' '}through{' '}
              <span className="font-bold text-blue-600 dark:text-blue-400">
                research-powered development
              </span>.
            </motion.p>

            <motion.div 
              className="flex flex-col sm:flex-row justify-center gap-6 mb-16"
              variants={fadeInUp}
            >
              <button
                onClick={() => setCurrentSection('about')}
                className="ai-button group"
              >
                Explore My Work
                <FaArrowRight className="inline-block ml-2 transform group-hover:translate-x-1 transition-transform" />
              </button>
              <button
                onClick={() => setCurrentSection('contact')}
                className="ai-button-secondary group"
              >
                Get in Touch
                <FaArrowRight className="inline-block ml-2 transform group-hover:translate-x-1 transition-transform" />
              </button>
            </motion.div>

            {/* Quick Stats */}
            <motion.div 
              className="grid grid-cols-2 md:grid-cols-4 gap-6 max-w-4xl mx-auto"
              variants={fadeInUp}
            >
              <div className="ai-card">
                <FaFileAlt className="w-8 h-8 text-blue-600 dark:text-blue-400 mx-auto mb-3" />
                <div className="text-3xl font-bold ai-gradient-text mb-2">
                  <CountUp end={4} duration={2.5} suffix="+" />
                </div>
                <div className="text-gray-600 dark:text-gray-300">Papers & Patents</div>
              </div>
              <div className="ai-card">
                <FaGraduationCap className="w-8 h-8 text-blue-600 dark:text-blue-400 mx-auto mb-3" />
                <div className="text-3xl font-bold ai-gradient-text mb-2">
                  <CountUp end={30} duration={2.5} suffix="+" />
                </div>
                <div className="text-gray-600 dark:text-gray-300">Projects</div>
              </div>
              <div className="ai-card">
                <FaClock className="w-8 h-8 text-blue-600 dark:text-blue-400 mx-auto mb-3" />
                <div className="text-3xl font-bold ai-gradient-text mb-2">
                  <CountUp end={3} duration={2.5} suffix="+" />
                </div>
                <div className="text-gray-600 dark:text-gray-300">Years Experience</div>
              </div>
              <div className="ai-card">
                <FaUsers className="w-8 h-8 text-blue-600 dark:text-blue-400 mx-auto mb-3" />
                <div className="text-3xl font-bold ai-gradient-text mb-2">
                  <CountUp end={300} duration={2.5} suffix="+" />
                </div>
                <div className="text-gray-600 dark:text-gray-300">Students Mentored</div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-bold mb-6 ai-gradient-text">What I Do</h2>
            <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              Leveraging cutting-edge technology to solve complex problems
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={service.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="ai-card hover:scale-105 transition-all duration-300"
              >
                <div className="text-blue-600 dark:text-blue-400 mb-4">
                  {service.icon}
                </div>
                <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                  {service.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  {service.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Featured Projects Section */}
      <section className="py-20 bg-slate-50/50 dark:bg-slate-900/50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4 ai-gradient-text">Featured Projects</h2>
            <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              Here are some of my major projects. Check out my{' '}
              <a 
                href="https://github.com/ascender1729" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-indigo-600 dark:text-indigo-400 hover:underline"
              >
                GitHub
              </a>
              {' '}for more projects.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
            <motion.a
              href="https://vibetensor.com/"
              target="_blank"
              rel="noopener noreferrer"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="bg-white dark:bg-slate-800 rounded-2xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 group"
            >
              <div className="aspect-[16/9] bg-gradient-to-br from-purple-500/90 to-blue-500/90 p-8 flex items-center justify-center">
                <img 
                  src={vibeTensorPreview}
                  alt="VibeTensor Preview" 
                  className="w-full h-full object-cover rounded-lg shadow-lg"
                />
              </div>
              <div className="p-8">
                <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4 group-hover:text-indigo-600 dark:group-hover:text-indigo-400 transition-colors">
                  VibeTensor
                </h3>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  AI-powered career development platform with real-time skill analysis and personalized growth paths. Helping professionals build future-ready careers through data-driven insights.
                </p>
                <div className="inline-flex items-center text-indigo-600 dark:text-indigo-400 font-medium">
                  Visit Site
                  <FaArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
                </div>
              </div>
            </motion.a>

            <motion.a
              href="https://dharalink.com/"
              target="_blank"
              rel="noopener noreferrer"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.1 }}
              viewport={{ once: true }}
              className="bg-white dark:bg-slate-800 rounded-2xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 group"
            >
              <div className="aspect-[16/9] bg-gradient-to-br from-green-500/90 to-emerald-500/90 p-8 flex items-center justify-center">
                <img 
                  src={dharaLinkPreview}
                  alt="DharaLink Preview" 
                  className="w-full h-full object-cover rounded-lg shadow-lg"
                />
              </div>
              <div className="p-8">
                <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4 group-hover:text-indigo-600 dark:group-hover:text-indigo-400 transition-colors">
                  DharaLink
                </h3>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  Smart farming platform revolutionizing agriculture through IoT sensors and AI insights. Helping farmers optimize crop yields and reduce water usage through technology.
                </p>
                <div className="inline-flex items-center text-indigo-600 dark:text-indigo-400 font-medium">
                  Visit Site
                  <FaArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
                </div>
              </div>
            </motion.a>
          </div>
        </div>
      </section>

      {/* Tech Stack Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-bold mb-6 ai-gradient-text">Tech Stack</h2>
            <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              Modern tools and technologies I work with
            </p>
          </motion.div>

          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
            {techStack.map((tech, index) => (
              <motion.div
                key={tech.name}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="ai-card flex flex-col items-center justify-center p-6 hover:scale-105 transition-all duration-300"
              >
                <div className="text-4xl text-blue-600 dark:text-blue-400 mb-3">
                  {tech.icon}
                </div>
                <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
                  {tech.name}
                </span>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="max-w-4xl mx-auto text-center"
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-8 ai-gradient-text">
              Let's Create Something Amazing Together
            </h2>
            <p className="text-xl text-gray-600 dark:text-gray-300 mb-12">
              Whether you're interested in collaboration, research opportunities, or just want to connect,
              I'd love to hear from you.
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center gap-6">
              <button
                onClick={() => setCurrentSection('contact')}
                className="ai-button group"
              >
                Start a Conversation
                <FaArrowRight className="inline-block ml-2 transform group-hover:translate-x-1 transition-transform" />
              </button>
              <div className="flex justify-center gap-4">
                <a
                  href="https://github.com/ascender1729"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ai-button-circle"
                  aria-label="GitHub Profile"
                >
                  <FaGithub className="w-6 h-6" />
                </a>
                <a
                  href="https://linkedin.com/in/im-pavankumar"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ai-button-circle"
                  aria-label="LinkedIn Profile"
                >
                  <FaLinkedin className="w-6 h-6" />
                </a>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Home;