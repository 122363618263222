import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes, FaHome, FaUser, FaBriefcase, FaGraduationCap, FaFlask, FaCode, FaCog, FaEnvelope } from 'react-icons/fa';

const Sidebar = ({ isOpen, setIsOpen, setCurrentSection, currentSection }) => {
  const navItems = [
    { id: 'home', label: 'Home', icon: <FaHome /> },
    { id: 'about', label: 'About', icon: <FaUser /> },
    { id: 'experience', label: 'Experience', icon: <FaBriefcase /> },
    { id: 'education', label: 'Education', icon: <FaGraduationCap /> },
    { id: 'research', label: 'Research', icon: <FaFlask /> },
    { id: 'projects', label: 'Projects', icon: <FaCode /> },
    { id: 'services', label: 'Services', icon: <FaCog /> },
    { id: 'contact', label: 'Contact', icon: <FaEnvelope /> }
  ];

  const sidebarVariants = {
    hidden: { 
      x: '100%',
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    },
    visible: { 
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40,
        staggerChildren: 0.1
      }
    },
    exit: {
      x: '100%',
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40,
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      x: 20,
      opacity: 0
    },
    visible: { 
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    exit: {
      x: 20,
      opacity: 0,
      transition: {
        duration: 0.2
      }
    }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 0.3
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.3,
        delay: 0.2
      }
    }
  };

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-black/30 backdrop-blur-sm z-40"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={overlayVariants}
            onClick={() => setIsOpen(false)}
          />
          <motion.div 
            className="fixed top-0 right-0 h-full w-64 sm:w-80 bg-white/90 dark:bg-slate-900/90 backdrop-blur-2xl border-l border-cyan-100/30 dark:border-cyan-900/30 shadow-[0_4px_16px_rgba(34,211,238,0.08)] dark:shadow-[0_4px_16px_rgba(34,211,238,0.15)] z-50"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={sidebarVariants}
          >
            <div className="p-6 flex flex-col h-full">
              <motion.button 
                onClick={() => setIsOpen(false)}
                className="self-end text-gray-600 dark:text-gray-300 p-2 hover:bg-cyan-50/30 dark:hover:bg-cyan-900/10 rounded-lg transition-colors duration-200"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <FaTimes className="w-6 h-6" />
              </motion.button>

              <nav className="mt-8 flex-grow">
                <ul className="space-y-2">
                  {navItems.map((item) => (
                    <motion.li 
                      key={item.id}
                      variants={itemVariants}
                      whileHover={{ scale: 1.02, x: 5 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <button 
                        onClick={() => {
                          setCurrentSection(item.id);
                          setIsOpen(false);
                        }}
                        className={`w-full flex items-center gap-4 px-4 py-3 rounded-lg transition-all duration-200
                          ${currentSection === item.id
                            ? 'bg-cyan-50/50 dark:bg-cyan-900/20 text-cyan-600 dark:text-cyan-400 font-medium'
                            : 'text-gray-600 dark:text-gray-300 hover:bg-cyan-50/30 dark:hover:bg-cyan-900/10'
                          }`}
                      >
                        <span className="text-xl">{item.icon}</span>
                        {item.label}
                      </button>
                    </motion.li>
                  ))}
                </ul>
              </nav>

              <div className="mt-auto pt-6 border-t border-cyan-100/30 dark:border-cyan-900/30">
                <p className="text-sm text-gray-500 dark:text-gray-400 text-center">
                  &copy; 2024-25 Dubasi Pavan Kumar
                </p>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default Sidebar;