import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  FaLinkedin, FaGithub, FaEnvelope, FaMapMarkerAlt,
  FaGraduationCap, FaArrowRight, FaPaperPlane
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { SiIeee, SiOrcid } from 'react-icons/si';

const Contact = () => {
  // Form data stored in refs for uncontrolled inputs
  const formRefs = {
    name: useRef(null),
    email: useRef(null),
    contact: useRef(null),
    subject: useRef(null),
    message: useRef(null)
  };
  
  // Keep errors and status in state
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Reference to the hidden form iframe
  const iframeRef = useRef(null);

  // Unique ID for this instance of the form
  const formId = useRef(`contact-form-${Math.random().toString(36).substring(2, 9)}`);

  // Create the hidden iframe only once when component mounts
  useEffect(() => {
    // Function to handle messages from the iframe
    const handleMessage = (event) => {
      console.log("Received message from iframe:", event.data);
      
      // Check if the message is from our form submission
      if (event.data && (event.data.status === 'success' || event.data.status === 'error')) {
        if (event.data.status === 'success') {
          setStatus('Message sent successfully! I will get back to you soon.');
          setShowSuccessMessage(true);
          document.getElementById(formId.current).reset();
        } else {
          setStatus(`Error: ${event.data.message || 'An error occurred. Please try again later.'}`);
        }
        setIsSubmitting(false);
      }
    };

    // Add the event listener
    window.addEventListener('message', handleMessage);

    // Create and append the iframe
    const iframe = document.createElement('iframe');
    iframe.name = `hidden-iframe-${formId.current}`;
    iframe.id = `hidden-iframe-${formId.current}`;
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    iframeRef.current = iframe;

    // Cleanup function
    return () => {
      window.removeEventListener('message', handleMessage);
      if (iframeRef.current && document.body.contains(iframeRef.current)) {
        document.body.removeChild(iframeRef.current);
      }
    };
  }, []);

  const validateForm = () => {
    // Get current values from refs
    const formData = {
      name: formRefs.name.current.value,
      email: formRefs.email.current.value,
      contact: formRefs.contact.current.value,
      subject: formRefs.subject.current.value,
      message: formRefs.message.current.value
    };
    
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    } else if (formData.name.length < 2) {
      newErrors.name = 'Name must be at least 2 characters long';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    const phoneRegex = /^\+?[\d\s-]{8,}$/;
    if (formData.contact && !phoneRegex.test(formData.contact)) {
      newErrors.contact = 'Please enter a valid phone number';
    }

    if (!formData.subject.trim()) {
      newErrors.subject = 'Subject is required';
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    } else if (formData.message.length < 10) {
      newErrors.message = 'Message must be at least 10 characters long';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Simple handler just to clear errors
  const handleChange = (e) => {
    const { name } = e.target;
    
    // Clear error for the field if it exists
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
    
    // Clear success message when user starts typing again
    if (showSuccessMessage) {
      setShowSuccessMessage(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    setStatus('Sending your message...');
    setShowSuccessMessage(false);

    try {
      // Get current values from refs for submission
      const data = {
        name: formRefs.name.current.value,
        email: formRefs.email.current.value,
        phone: formRefs.contact.current.value || '',
        subject: formRefs.subject.current.value,
        message: formRefs.message.current.value
      };

      console.log('Sending data:', data);
      
      // Script URL from Google Apps Script
      const scriptURL = "https://script.google.com/macros/s/AKfycbyhCWMKhCeAVyib8XfXZnxvG9qm8UGMLMqOTa8Mw83g5geGFgBzXASYpVtUKis8_PZYSg/exec";
      
      // Create a form element
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = scriptURL;
      form.target = '_blank'; // This will open in a new tab temporarily
      
      // Add form fields
      Object.entries(data).forEach(([key, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });
      
      // Add to document, submit, and remove
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);

      // Set success message
      setTimeout(() => {
        setStatus('Your message has been sent! I will get back to you soon.');
        setShowSuccessMessage(true);
        document.getElementById(formId.current).reset();
        setIsSubmitting(false);
      }, 1000);
      
    } catch (error) {
      console.error('Form submission error:', error);
      setStatus(`Error: ${error.message || 'Failed to send message. Please check your connection and try again. If the problem persists, you can email me directly at pavankumar.dubasi2019@gmail.com'}`);
      setIsSubmitting(false);
    }
  };

  const contactInfo = [
    {
      icon: <FaMapMarkerAlt className="w-6 h-6" />,
      title: "Location",
      content: "Bengaluru, Karnataka, India",
      color: "text-red-500"
    },
    {
      icon: <FaEnvelope className="w-6 h-6" />,
      title: "Email",
      content: "pavankumar.dubasi2019@gmail.com",
      link: "mailto:pavankumar.dubasi2019@gmail.com",
      color: "text-blue-500"
    }
  ];

  const socialLinks = [
    {
      icon: <FaLinkedin className="w-6 h-6" />,
      url: "https://linkedin.com/in/im-pavankumar",
      label: "LinkedIn",
      color: "hover:text-blue-600"
    },
    {
      icon: <FaGithub className="w-6 h-6" />,
      url: "https://github.com/ascender1729",
      label: "GitHub",
      color: "hover:text-gray-900 dark:hover:text-white"
    },
    {
      icon: <FaXTwitter className="w-6 h-6" />,
      url: "https://twitter.com/the_complex_one",
      label: "Twitter",
      color: "hover:text-blue-400"
    },
    {
      icon: <SiIeee className="w-6 h-6" />,
      url: "https://ieee-collabratec.ieee.org/app/p/pavankumar",
      label: "IEEE",
      color: "hover:text-blue-700"
    },
    {
      icon: <SiOrcid className="w-6 h-6" />,
      url: "https://orcid.org/0009-0006-1060-4598",
      label: "ORCID",
      color: "hover:text-green-600"
    },
    {
      icon: <FaGraduationCap className="w-6 h-6" />,
      url: "https://scholar.google.com/citations?user=0hGebsQAAAAJ",
      label: "Google Scholar",
      color: "hover:text-blue-800"
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  // Modified InputField to use uncontrolled inputs
  const InputField = ({ label, type, name, onChange, error, placeholder, required = true }) => (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <input
        ref={formRefs[name]}
        type={type}
        id={name}
        name={name}
        onChange={onChange}
        required={required}
        className={`ai-input ${error ? 'border-red-500 dark:border-red-500' : ''}`}
        placeholder={placeholder}
        autoComplete="off"
        spellCheck="false"
      />
      {error && (
        <p className="mt-1 text-sm text-red-500">{error}</p>
      )}
    </div>
  );

  return (
    <section className="py-12 sm:py-16 md:py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-8 sm:mb-12 md:mb-16"
        >
          <h2 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6 bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400 text-transparent bg-clip-text">
            Get in Touch
          </h2>
          <p className="text-lg sm:text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Let's discuss your project ideas or research collaboration opportunities.
          </p>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="max-w-6xl mx-auto"
        >
          <motion.div 
            variants={itemVariants}
            className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 mb-8 sm:mb-12"
          >
            {contactInfo.map((info, index) => (
              <div
                key={index}
                className="ai-card hover:shadow-xl transition-all duration-300 p-4 sm:p-6"
              >
                <div className="flex items-center gap-3 sm:gap-4">
                  <div className={`${info.color} p-2 sm:p-3 rounded-full bg-white/10 dark:bg-gray-800/10`}>
                    {info.icon}
                  </div>
                  <div className="flex-1 min-w-0">
                    <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-1">
                      {info.title}
                    </h3>
                    {info.link ? (
                      <a
                        href={info.link}
                        className="text-sm sm:text-base text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors truncate block"
                      >
                        {info.content}
                      </a>
                    ) : (
                      <p className="text-sm sm:text-base text-gray-600 dark:text-gray-300 truncate">{info.content}</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </motion.div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 sm:gap-12">
            <motion.div variants={itemVariants} className="w-full">
              {showSuccessMessage ? (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="ai-card p-6 sm:p-8 text-center"
                >
                  <div className="flex flex-col items-center justify-center space-y-4">
                    <div className="w-16 h-16 bg-green-100 dark:bg-green-900/30 rounded-full flex items-center justify-center text-green-500 dark:text-green-400">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <h3 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
                      Thank You!
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      Your message has been sent successfully. I'll get back to you soon!
                    </p>
                    <button
                      onClick={() => setShowSuccessMessage(false)}
                      className="mt-4 ai-button py-2 px-4"
                    >
                      Send Another Message
                    </button>
                  </div>
                </motion.div>
              ) : (
                <form id={formId.current} onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                    <InputField
                      label="Name"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      error={errors.name}
                      placeholder="Your name"
                    />
                    <InputField
                      label="Email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      error={errors.email}
                      placeholder="your.email@example.com"
                    />
                  </div>

                  <InputField
                    label="Contact Number"
                    type="tel"
                    name="contact"
                    onChange={handleChange}
                    error={errors.contact}
                    placeholder="+1 (234) 567-8900"
                    required={false}
                  />

                  <InputField
                    label="Subject"
                    type="text"
                    name="subject"
                    onChange={handleChange}
                    error={errors.subject}
                    placeholder="Project discussion"
                  />

                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Message <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      ref={formRefs.message}
                      id="message"
                      name="message"
                      onChange={handleChange}
                      required
                      rows="4"
                      className={`ai-input !rounded-2xl resize-none w-full ${errors.message ? 'border-red-500 dark:border-red-500' : ''}`}
                      placeholder="Tell me about your project or research idea..."
                      autoComplete="off"
                      spellCheck="false"
                    ></textarea>
                    {errors.message && (
                      <p className="mt-1 text-sm text-red-500">{errors.message}</p>
                    )}
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="ai-button group w-full flex items-center justify-center py-3 sm:py-4"
                  >
                    {isSubmitting ? (
                      <span className="flex items-center">
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Sending...
                      </span>
                    ) : (
                      <>
                        Send Message
                        <FaPaperPlane className="ml-2 group-hover:translate-x-1 transition-transform" />
                      </>
                    )}
                  </button>

                  {status && !showSuccessMessage && (
                    <motion.p
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      className={`text-center text-sm sm:text-base ${
                        status.includes('successfully') || status.includes('has been sent')
                          ? 'text-green-600 dark:text-green-400'
                          : 'text-red-600 dark:text-red-400'
                      }`}
                    >
                      {status}
                    </motion.p>
                  )}
                </form>
              )}
            </motion.div>

            <motion.div variants={itemVariants} className="space-y-6 sm:space-y-8">
              <div className="ai-card p-4 sm:p-6">
                <h3 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white mb-4 sm:mb-6">
                  Connect with Me
                </h3>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 sm:gap-4">
                  {socialLinks.map((link, index) => (
                    <a
                      key={index}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`flex items-center gap-2 sm:gap-3 p-3 sm:p-4 rounded-xl bg-white/5 dark:bg-gray-800/5 
                        border border-gray-200/20 dark:border-gray-700/20 
                        text-gray-600 dark:text-gray-300 ${link.color}
                        transition-all duration-300 hover:scale-105 hover:shadow-lg`}
                    >
                      {link.icon}
                      <span className="text-xs sm:text-sm font-medium">{link.label}</span>
                    </a>
                  ))}
                </div>
              </div>

              <div className="ai-card p-4 sm:p-6">
                <h3 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white mb-3 sm:mb-4">
                  Office Hours
                </h3>
                <div className="space-y-2 text-sm sm:text-base text-gray-600 dark:text-gray-300">
                  <p>Monday - Friday: 10:00 AM - 4:00 PM (IST)</p>
                  <p>Saturday & Sunday: Closed</p>
                </div>
              </div>

              <div className="ai-card p-4 sm:p-6">
                <h3 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white mb-3 sm:mb-4">
                  Quick Response
                </h3>
                <p className="text-sm sm:text-base text-gray-600 dark:text-gray-300">
                  I typically respond to messages within 24 hours. For urgent matters, 
                  please feel free to reach out via email during office hours.
                </p>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;