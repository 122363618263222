import React, { useState } from 'react';
import Select from 'react-select';
import { motion } from 'framer-motion';
import { FaGithub, FaGamepad, FaExternalLinkAlt, FaCode, FaServer, FaMobileAlt } from 'react-icons/fa';
import { SiTensorflow, SiPytorch, SiDocker } from 'react-icons/si';
import urbanLogo from '../images/urban.jpg';
import healthmlchainLogo from '../images/healthmlchain.jpg';
import cosmicLogo from '../images/cosmic.png';
import codesageLogo from '../images/codesage.jpg';
import qwasteLogo from '../images/qWaste.jpg';
import vibeTensorPreview from '../images/vibetensor-home.png';
import dharaLinkPreview from '../images/dharalink-home.png';

const projects = [
  {
    title: "VibeTensor",
    description: "A modern, AI-powered career development platform that helps professionals build future-ready careers with personalized insights and training recommendations. Features an interactive dashboard, smart skill matching, and real-time analytics for career market trends.",
    technologies: ["React", "TypeScript", "Vite", "TailwindCSS", "Zustand", "Framer Motion", "Supabase", "Recharts", "React Hook Form"],
    domains: ["Career Tech", "Machine Learning", "Web Development"],
    link: "https://github.com/ascender1729/vibetensor",
    demoLink: "https://vibetensor.com",
    image: vibeTensorPreview,
    highlights: [
      "AI-powered career insights and recommendations",
      "Interactive progress tracking dashboard",
      "Smart skill-role matching system",
      "Personalized learning paths",
      "Real-time career market analytics",
      "Modern UI with smooth animations"
    ]
  },
  {
    title: "DharaLink",
    description: "A technology platform transforming Indian agriculture through smart IoT solutions and data-driven insights. Features a modern, responsive interface built with React and TypeScript, optimized for performance and accessibility.",
    technologies: ["React", "TypeScript", "Tailwind CSS", "Vite", "Framer Motion", "Lucide React"],
    domains: ["Agriculture Tech", "Web Development", "IoT"],
    link: "https://github.com/ascender1729/dharalink",
    demoLink: "https://dharalink.com",
    image: dharaLinkPreview,
    highlights: [
      "Responsive design for all devices",
      "Optimized performance metrics",
      "SEO-friendly architecture",
      "Mobile-first approach",
      "Smooth animations and transitions",
      "Accessibility-focused implementation"
    ]
  },
  {
    title: "HealthFraudMLChain",
    description: "A blockchain and machine learning-based healthcare insurance fraud detection system achieving 99.93% accuracy. Implements ECIES encryption with Hyperledger for secure data handling.",
    technologies: ["Python", "TensorFlow", "Hyperledger", "Flask", "React", "Solidity"],
    domains: ["Machine Learning", "Blockchain", "Healthcare"],
    link: "https://github.com/ascender1729/HealthFraudMLChain",
    demoLink: "https://healthfraud-demo.netlify.app",
    image: healthmlchainLogo,
    highlights: [
      "99.93% fraud detection accuracy",
      "ECIES encryption implementation",
      "Real-time fraud analysis",
      "Secure data handling"
    ]
  },
  {
    title: "Cosmic Defender",
    description: "An immersive 3D space shooter game where players navigate through asteroid fields, destroy threats, and collect power-ups. Built with Three.js for stunning visual effects.",
    technologies: ["Three.js", "JavaScript", "WebGL", "Howler.js", "HTML5", "CSS3"],
    domains: ["Game Development", "Web Development"],
    link: "https://github.com/ascender1729/asteroid-dodge",
    playLink: "https://ascender1729.github.io/asteroid-dodge/",
    image: cosmicLogo,
    highlights: [
      "3D graphics rendering",
      "Physics-based gameplay",
      "Dynamic sound effects",
      "Responsive controls"
    ]
  },
  {
    title: "QuantumWaste",
    description: "An innovative system leveraging quantum-inspired algorithms and machine learning to optimize polymer recycling processes. Combines quantum computing concepts with materials science.",
    technologies: ["React", "Flask", "Three.js", "PennyLane", "Scikit-learn", "Material-UI"],
    domains: ["Quantum Computing", "Materials Science", "Web Development"],
    link: "https://github.com/ascender1729/QuantumWaste",
    image: qwasteLogo,
    highlights: [
      "Quantum algorithm implementation",
      "3D molecular visualization",
      "Real-time optimization",
      "Interactive simulations"
    ]
  },
  {
    title: "Urban Soundscape Harmonizer",
    description: "A comprehensive urban noise monitoring and analysis system that leverages IoT simulations and machine learning to track noise levels across major Indian cities in real-time.",
    technologies: ["React", "FastAPI", "InfluxDB", "TensorFlow", "Leaflet", "Material-UI"],
    domains: ["IoT", "Data Science", "Web Development"],
    link: "https://github.com/ascender1729/urban-soundscape-harmonizer",
    demoLink: "https://soundscape-demo.netlify.app",
    image: urbanLogo,
    highlights: [
      "Real-time noise analysis",
      "Interactive city mapping",
      "ML-based predictions",
      "Time-series visualization"
    ]
  },
  {
    title: "CodeSage",
    description: "An advanced code review assistant that enhances the software development process through automated insights, best practice suggestions, and code quality maintenance.",
    technologies: ["Python", "Flask", "AST", "Git Integration", "Natural Language Processing"],
    domains: ["Developer Tools", "Machine Learning"],
    link: "https://github.com/ascender1729/CodeSage",
    image: codesageLogo,
    highlights: [
      "Automated code analysis",
      "Best practice detection",
      "Security vulnerability scanning",
      "Performance optimization tips"
    ]
  }
];

const Projects = () => {
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [hoveredProject, setHoveredProject] = useState(null);

  const handleDomainChange = (selectedOptions) => {
    setSelectedDomains(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const handleTechnologyChange = (selectedOptions) => {
    setSelectedTechnologies(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const uniqueDomains = Array.from(new Set(projects.flatMap(project => project.domains)));
  const uniqueTechnologies = Array.from(new Set(projects.flatMap(project => project.technologies)));

  const domainOptions = uniqueDomains.map(domain => ({ value: domain, label: domain }));
  const technologyOptions = uniqueTechnologies.map(tech => ({ value: tech, label: tech }));

  const filteredProjects = projects.filter(project => 
    (selectedDomains.length === 0 || selectedDomains.some(domain => project.domains.includes(domain))) &&
    (selectedTechnologies.length === 0 || selectedTechnologies.every(tech => project.technologies.includes(tech)))
  );

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      borderRadius: '16px',
      padding: '4px',
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      '&:hover': {
        border: '1px solid rgba(59, 130, 246, 0.5)',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      borderRadius: '16px',
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected 
        ? 'rgba(59, 130, 246, 0.2)'
        : state.isFocused 
          ? 'rgba(59, 130, 246, 0.1)'
          : 'transparent',
      color: state.isSelected ? '#2563EB' : '#374151',
      '&:hover': {
        backgroundColor: 'rgba(59, 130, 246, 0.1)',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(59, 130, 246, 0.2)',
      borderRadius: '8px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#2563EB',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#2563EB',
      '&:hover': {
        backgroundColor: 'rgba(59, 130, 246, 0.3)',
        color: '#1E40AF',
      },
    }),
  };

  const getProjectIcon = (domains) => {
    if (domains.includes('Game Development')) return <FaGamepad className="w-6 h-6" />;
    if (domains.includes('Machine Learning')) return <SiTensorflow className="w-6 h-6" />;
    if (domains.includes('Developer Tools')) return <FaCode className="w-6 h-6" />;
    if (domains.includes('IoT')) return <FaMobileAlt className="w-6 h-6" />;
    return <FaServer className="w-6 h-6" />;
  };

  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400 text-transparent bg-clip-text"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Featured Projects
        </motion.h2>
        
        <motion.div 
          className="max-w-4xl mx-auto mb-12 space-y-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <p className="text-xl text-center text-gray-600 dark:text-gray-300">
            Explore my portfolio of innovative projects spanning machine learning, web development, and more.
          </p>
          
          <div className="flex flex-col md:flex-row justify-center gap-4">
            <div className="w-full md:w-1/2">
              <Select
                options={domainOptions}
                isMulti
                placeholder="Filter by Domain"
                onChange={handleDomainChange}
                styles={customSelectStyles}
                className="text-gray-900 dark:text-gray-100"
              />
            </div>
            <div className="w-full md:w-1/2">
              <Select
                options={technologyOptions}
                isMulti
                placeholder="Filter by Technology"
                onChange={handleTechnologyChange}
                styles={customSelectStyles}
                className="text-gray-900 dark:text-gray-100"
              />
            </div>
          </div>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="grid grid-cols-1 gap-8"
        >
          {filteredProjects.map((project, index) => (
            <motion.div
              key={project.title}
              variants={itemVariants}
              className="ai-card overflow-hidden"
              onHoverStart={() => setHoveredProject(project.title)}
              onHoverEnd={() => setHoveredProject(null)}
            >
              <div className="flex flex-col lg:flex-row gap-8">
                <div className="lg:w-2/5">
                  <div className="relative aspect-video rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
                    <img
                      src={project.image}
                      alt={project.title}
                      className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                    />
                  </div>
                </div>
                
                <div className="lg:w-3/5">
                  <div className="flex items-center gap-3 mb-4">
                    <span className="text-blue-600 dark:text-blue-400">
                      {getProjectIcon(project.domains)}
                    </span>
                    <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                      {project.title}
                    </h3>
                  </div>
                  
                  <p className="text-gray-600 dark:text-gray-300 mb-6">
                    {project.description}
                  </p>
                  
                  <div className="space-y-4 mb-6">
                    <div className="flex flex-wrap gap-2">
                      {project.technologies.map((tech) => (
                        <span
                          key={tech}
                          className="px-3 py-1 text-sm font-medium text-blue-600 dark:text-blue-400 bg-blue-50/30 dark:bg-blue-900/30 rounded-full"
                        >
                          {tech}
                        </span>
                      ))}
                    </div>
                    
                    <div className="flex flex-wrap gap-2">
                      {project.domains.map((domain) => (
                        <span
                          key={domain}
                          className="px-3 py-1 text-sm font-medium text-purple-600 dark:text-purple-400 bg-purple-50/30 dark:bg-purple-900/30 rounded-full"
                        >
                          {domain}
                        </span>
                      ))}
                    </div>
                  </div>
                  
                  <div className="space-y-4">
                    <h4 className="font-semibold text-gray-800 dark:text-gray-200">Key Features:</h4>
                    <ul className="grid grid-cols-1 md:grid-cols-2 gap-2">
                      {project.highlights.map((highlight, idx) => (
                        <li
                          key={idx}
                          className="flex items-center text-gray-600 dark:text-gray-300"
                        >
                          <span className="w-2 h-2 bg-blue-500 rounded-full mr-2" />
                          {highlight}
                        </li>
                      ))}
                    </ul>
                  </div>
                  
                  <div className="flex flex-wrap gap-4 mt-6">
                    <a
                      href={project.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ai-button-secondary group inline-flex items-center"
                    >
                      <FaGithub className="mr-2" />
                      View Code
                      <FaExternalLinkAlt className="ml-2 group-hover:translate-x-1 transition-transform" />
                    </a>
                    {project.playLink && (
                      <a
                        href={project.playLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ai-button group inline-flex items-center"
                      >
                        <FaGamepad className="mr-2" />
                        Play Demo
                        <FaExternalLinkAlt className="ml-2 group-hover:translate-x-1 transition-transform" />
                      </a>
                    )}
                    {project.demoLink && !project.playLink && (
                      <a
                        href={project.demoLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ai-button group inline-flex items-center"
                      >
                        View Demo
                        <FaExternalLinkAlt className="ml-2 group-hover:translate-x-1 transition-transform" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Projects;