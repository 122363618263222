import React, { useState, useEffect } from 'react';
import { motion, useScroll } from 'framer-motion';
import { FaMoon, FaSun, FaBars, FaTimes } from 'react-icons/fa';

const Header = ({ isMenuOpen, setIsMenuOpen, isDarkMode, toggleDarkMode, currentSection, setCurrentSection }) => {
  const navItems = ['Home', 'About', 'Experience', 'Education', 'Research', 'Projects', 'Services', 'Contact'];
  const { scrollY } = useScroll();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    return scrollY.onChange((latest) => {
      setIsScrolled(latest > 50);
    });
  }, [scrollY]);

  const headerVariants = {
    hidden: { y: -100 },
    visible: { 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  return (
    <motion.header 
      className={`fixed bg-white/90 dark:bg-slate-900/90 backdrop-blur-2xl border border-cyan-100/30 dark:border-cyan-900/30 shadow-[0_4px_16px_rgba(34,211,238,0.08)] dark:shadow-[0_4px_16px_rgba(34,211,238,0.15)] z-50 rounded-2xl transition-all duration-300 py-4
        ${isScrolled 
          ? 'w-[85%] left-[7.5%]' 
          : 'w-[95%] left-[2.5%]'
        } top-4`}
      initial="hidden"
      animate="visible"
      variants={headerVariants}
    >
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <motion.h1 
            className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-cyan-500 via-blue-500 to-purple-500 dark:from-cyan-400 dark:via-blue-400 dark:to-purple-400 text-transparent bg-clip-text cursor-pointer"
            onClick={() => setCurrentSection('home')}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            DPK
          </motion.h1>

          <nav className="hidden lg:flex items-center space-x-1">
            {navItems.map((item) => (
              <motion.button
                key={item}
                onClick={() => setCurrentSection(item.toLowerCase())}
                className={`relative px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                  ${currentSection === item.toLowerCase()
                    ? 'text-cyan-600 dark:text-cyan-400 bg-cyan-50/50 dark:bg-cyan-900/20'
                    : 'text-gray-700 dark:text-gray-200 hover:text-cyan-600 dark:hover:text-cyan-400 hover:bg-cyan-50/30 dark:hover:bg-cyan-900/10'
                  }`}
                variants={itemVariants}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {item}
                {currentSection === item.toLowerCase() && (
                  <motion.div
                    className="absolute bottom-0 left-0 right-0 h-0.5 bg-cyan-600 dark:bg-cyan-400"
                    layoutId="underline"
                  />
                )}
              </motion.button>
            ))}

            <motion.button 
              onClick={toggleDarkMode}
              className="ml-4 p-2 rounded-lg text-gray-700 dark:text-gray-200 hover:bg-cyan-50/30 dark:hover:bg-cyan-900/10 transition-colors duration-200"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              variants={itemVariants}
            >
              {isDarkMode ? <FaSun className="w-5 h-5" /> : <FaMoon className="w-5 h-5" />}
            </motion.button>
          </nav>

          <div className="flex items-center space-x-4 lg:hidden">
            <motion.button 
              onClick={toggleDarkMode}
              className="p-2 rounded-lg text-gray-700 dark:text-gray-200 hover:bg-cyan-50/30 dark:hover:bg-cyan-900/10 transition-colors duration-200"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {isDarkMode ? <FaSun className="w-5 h-5" /> : <FaMoon className="w-5 h-5" />}
            </motion.button>

            <motion.button 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 rounded-lg text-gray-700 dark:text-gray-200 hover:bg-cyan-50/30 dark:hover:bg-cyan-900/10 transition-colors duration-200"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {isMenuOpen ? <FaTimes className="w-5 h-5" /> : <FaBars className="w-5 h-5" />}
            </motion.button>
          </div>
        </div>
      </div>
    </motion.header>
  );
};

export default Header;