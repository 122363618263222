import React from 'react';
import { motion } from 'framer-motion';
import { 
  FaBrain, FaCode, FaChartLine, FaServer, FaTools, FaBookOpen, 
  FaDatabase, FaMobileAlt, FaCloud, FaRobot, FaShieldAlt, FaUserGraduate 
} from 'react-icons/fa';

const services = [
  {
    title: "Data Science & AI",
    icon: <FaBrain className="w-12 h-12" />,
    description: "Transforming data into actionable insights using cutting-edge AI and machine learning techniques.",
    expertise: [
      {
        title: "Machine Learning",
        items: ["Predictive Modeling", "Classification & Regression", "Neural Networks", "Time Series Analysis"]
      },
      {
        title: "Deep Learning",
        items: ["Computer Vision", "Natural Language Processing", "Reinforcement Learning", "GANs"]
      },
      {
        title: "Data Analytics",
        items: ["Statistical Analysis", "Data Visualization", "Feature Engineering", "A/B Testing"]
      }
    ],
    tools: ["TensorFlow", "PyTorch", "Scikit-learn", "Pandas", "NumPy", "OpenCV"],
    highlight: "99.93% accuracy in fraud detection systems"
  },
  {
    title: "Full Stack Development",
    icon: <FaCode className="w-12 h-12" />,
    description: "Building scalable, responsive, and modern web applications with the latest technologies.",
    expertise: [
      {
        title: "Frontend",
        items: ["React.js", "Next.js", "TypeScript", "Tailwind CSS", "Material-UI"]
      },
      {
        title: "Backend",
        items: ["Node.js", "Express", "FastAPI", "GraphQL", "RESTful APIs"]
      },
      {
        title: "Database",
        items: ["PostgreSQL", "MongoDB", "Redis", "Firebase", "Supabase"]
      }
    ],
    tools: ["Git", "Docker", "AWS", "Vercel", "Netlify", "Jest"],
    highlight: "Developed high-performance applications serving 100K+ users"
  },
  {
    title: "Research & Innovation",
    icon: <FaBookOpen className="w-12 h-12" />,
    description: "Conducting cutting-edge research in AI, quantum computing, and emerging technologies.",
    expertise: [
      {
        title: "Research Areas",
        items: ["Quantum Machine Learning", "Healthcare AI", "Blockchain", "IoT Systems"]
      },
      {
        title: "Publications",
        items: ["IEEE Papers", "International Conferences", "Patent Filings", "Technical Reports"]
      },
      {
        title: "Collaboration",
        items: ["Academic Research", "Industry Projects", "Open Source", "Tech Workshops"]
      }
    ],
    tools: ["LaTeX", "Jupyter", "PennyLane", "Qiskit", "Research Gate", "Google Scholar"],
    highlight: "Published 4+ research papers in leading journals"
  },
  {
    title: "Technical Consulting",
    icon: <FaTools className="w-12 h-12" />,
    description: "Providing expert guidance on technology strategy, architecture, and implementation.",
    expertise: [
      {
        title: "Strategy",
        items: ["Tech Stack Selection", "Architecture Design", "Scalability Planning", "Security Assessment"]
      },
      {
        title: "Implementation",
        items: ["Best Practices", "Code Review", "Performance Optimization", "DevOps Setup"]
      },
      {
        title: "Training",
        items: ["Team Workshops", "Technical Mentoring", "Code Quality", "Documentation"]
      }
    ],
    tools: ["JIRA", "Confluence", "GitHub", "Azure DevOps", "Slack", "Notion"],
    highlight: "Mentored 300+ students in tech & research"
  }
];

const Services = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400 text-transparent bg-clip-text">
            Professional Services
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Leveraging cutting-edge technology and research expertise to deliver innovative solutions across multiple domains.
          </p>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="grid grid-cols-1 gap-8"
        >
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              variants={itemVariants}
              className="ai-card hover:shadow-xl transition-all duration-300"
            >
              <div className="flex flex-col lg:flex-row gap-8">
                <div className="lg:w-1/3">
                  <div className="flex items-center gap-4 mb-6">
                    <div className="text-blue-600 dark:text-blue-400">
                      {service.icon}
                    </div>
                    <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                      {service.title}
                    </h3>
                  </div>
                  
                  <p className="text-gray-600 dark:text-gray-300 mb-6">
                    {service.description}
                  </p>

                  <div className="p-4 bg-blue-50/30 dark:bg-blue-900/30 rounded-xl mb-6">
                    <div className="text-sm font-medium text-blue-600 dark:text-blue-400 mb-2">
                      Highlight
                    </div>
                    <div className="text-gray-800 dark:text-gray-200 font-medium">
                      {service.highlight}
                    </div>
                  </div>

                  <div className="flex flex-wrap gap-2">
                    {service.tools.map((tool) => (
                      <span
                        key={tool}
                        className="px-3 py-1 text-sm font-medium text-purple-600 dark:text-purple-400 bg-purple-50/30 dark:bg-purple-900/30 rounded-full"
                      >
                        {tool}
                      </span>
                    ))}
                  </div>
                </div>

                <div className="lg:w-2/3">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {service.expertise.map((area) => (
                      <div
                        key={area.title}
                        className="p-6 bg-white/5 dark:bg-gray-800/5 rounded-xl border border-gray-200/20 dark:border-gray-700/20"
                      >
                        <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">
                          {area.title}
                        </h4>
                        <ul className="space-y-2">
                          {area.items.map((item) => (
                            <li
                              key={item}
                              className="flex items-center text-gray-600 dark:text-gray-300"
                            >
                              <span className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2" />
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="text-center mt-16"
        >
          <h3 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">
            Ready to Start a Project?
          </h3>
          <a 
            href="mailto:pavankumar.dubasi2019@gmail.com" 
            className="ai-button group inline-flex items-center"
          >
            Get in Touch
            <FaUserGraduate className="ml-2 group-hover:translate-x-1 transition-transform" />
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default Services;