import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import AnimatedCursorComponent from './components/AnimatedCursorComponent';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import About from './components/About';
import Experience from './components/Experience';
import Education from './components/Education';
import Research from './components/Research';
import Projects from './components/Projects';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './styles/globals.css';
import { ThemeProvider } from './context/ThemeContext';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme === 'dark' : window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  // Initialize currentSection from URL hash or default to 'home'
  const [currentSection, setCurrentSection] = useState(() => {
    const hash = window.location.hash.replace('#', '');
    return hash || 'home';
  });

  useEffect(() => {
    document.documentElement.classList.toggle('dark', isDarkMode);
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  // Update URL hash when section changes
  useEffect(() => {
    window.location.hash = currentSection;
  }, [currentSection]);

  // Listen for hash changes
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace('#', '');
      if (hash) {
        setCurrentSection(hash);
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);

  const sections = [
    { id: 'home', component: Home },
    { id: 'about', component: About },
    { id: 'experience', component: Experience },
    { id: 'education', component: Education },
    { id: 'research', component: Research },
    { id: 'projects', component: Projects },
    { id: 'services', component: Services },
    { id: 'contact', component: Contact },
  ];

  const pageVariants = {
    initial: { 
      opacity: 0, 
      y: 20,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    exit: { 
      opacity: 0, 
      y: -20,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  return (
    <ThemeProvider>
      <div className="min-h-screen transition-colors duration-300">
        <AnimatedCursorComponent>
          <div className="flex flex-col min-h-screen">
            <div className="neural-bg flex-grow">
              <Header 
                isMenuOpen={isMenuOpen} 
                setIsMenuOpen={setIsMenuOpen} 
                isDarkMode={isDarkMode} 
                toggleDarkMode={toggleDarkMode}
                currentSection={currentSection}
                setCurrentSection={setCurrentSection}
              />
              <Sidebar 
                isOpen={isMenuOpen} 
                setIsOpen={setIsMenuOpen} 
                setCurrentSection={setCurrentSection}
                currentSection={currentSection}
              />
              <main className="container mx-auto px-4 pt-20 pb-10 flex-grow">
                <AnimatePresence mode='wait'>
                  {sections.map(({ id, component: Component }) => (
                    currentSection === id && (
                      <motion.div
                        key={id}
                        variants={pageVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        className="w-full"
                      >
                        <Component setCurrentSection={setCurrentSection} />
                      </motion.div>
                    )
                  ))}
                </AnimatePresence>
              </main>
            </div>
            <Footer />
          </div>
        </AnimatedCursorComponent>
      </div>
    </ThemeProvider>
  );
}

export default App;