import React from 'react';
import { motion } from 'framer-motion';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

// Import logos
import nitPatnaLogo from '../images/nit-patna-logo.png';
import narayanaLogo from '../images/narayana-logo.png';
import tejaswiLogo from '../images/tejaswi-logo.png';

const educations = [
  {
    degree: "Integrated Master of Science, Mathematics",
    institution: "National Institute of Technology, Patna",
    year: "July 2019 - June 2024",
    grade: "CGPA: 8.89/10",
    description: "Achieved rank 1 with the highest absolute CGPA in the department.",
    achievements: [
      "Department's Highest CGPA",
      "Research Publication in IEEE",
      "Best Final Year Project Award",
      "Mathematics Department Representative"
    ],
    logo: nitPatnaLogo
  },
  {
    degree: "Senior Secondary, MPC",
    institution: "Narayana Junior College",
    year: "June 2017 - May 2019",
    grade: "Percentage: 93.3%",
    description: "Focused on Mathematics, Physics, and Chemistry.",
    achievements: [
      "Top 7% of the State",
      "Top 2.3% in Mathematics Statewide",
      "Overall A Gradet"
    ],
    logo: narayanaLogo
  },
  {
    degree: "Secondary",
    institution: "Tejaswi High School",
    year: "June 2016 - May 2017",
    grade: "CGPA: 9.7/10",
    description: "Completed secondary education with distinction.",
    achievements: [
      "Perfect Score in Mathematics, English, and Science",
      "Top 3% of the State",
      "Overall A1 Grade"
    ],
    logo: tejaswiLogo
  }
];

const Education = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400 text-transparent bg-clip-text"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Educational Journey
        </motion.h2>

        <VerticalTimeline lineColor="rgba(59, 130, 246, 0.2)">
          {educations.map((edu, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--education"
              contentStyle={{ 
                background: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                borderRadius: '16px',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)'
              }}
              contentArrowStyle={{ borderRight: '7px solid rgba(255, 255, 255, 0.1)' }}
              date={edu.year}
              dateClassName="text-gray-700 dark:text-gray-300"
              iconStyle={{ 
                background: 'rgba(255, 255, 255, 0.8)',
                boxShadow: '0 0 0 4px rgba(59, 130, 246, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                width: '60px',
                height: '60px'
              }}
              icon={
                <img 
                  src={edu.logo}
                  alt={`${edu.institution} logo`}
                  className="w-[80%] h-[80%] object-contain"
                />
              }
            >
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">{edu.degree}</h3>
              <h4 className="text-xl font-semibold text-blue-600 dark:text-blue-400 mb-2">{edu.institution}</h4>
              <p className="text-lg font-medium text-gray-600 dark:text-gray-400 mb-4">{edu.grade}</p>
              <p className="text-gray-700 dark:text-gray-300 mb-4">{edu.description}</p>
              
              <div className="space-y-2">
                <h5 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2">Key Achievements:</h5>
                <ul className="list-disc list-inside space-y-1">
                  {edu.achievements.map((achievement, i) => (
                    <li key={i} className="text-gray-700 dark:text-gray-300">{achievement}</li>
                  ))}
                </ul>
              </div>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </section>
  );
};

export default Education;