import React from 'react';
import { motion } from 'framer-motion';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

// Import logos
import nitPatnaLogo from '../images/nit-patna-logo.png';
import eutHubLogo from '../images/eut-hub-logo.png';
import arvoLogo from '../images/arvo-logo.png';
import innodataLogo from '../images/innodata.jpg';
import gcriLogo from '../images/gcri.png';


const experiences = [
  {
    title: "AI Engineer - Climate Heatwave Project",
    company: "The Global Centre for Risk and Innovation (GCRI)",
    startDate: "February 2025",
    endDate: "Present",
    location: "Remote",
    description: [
      "Optimizing Heatwave Prediction – Analyzing NASA-IBM weather models with 160+ atmospheric variables to identify optimal data sources for high-resolution (2.5-3km) forecasting.",
      "Dataset Evaluation & Integration – Comparing 12+ meteorological datasets (URMA, RTMA, ERA5-Land, GOES-16/17) and collaborating with cross-functional teams to enhance GCRI's open-source platform.",
      "Research & Scientific Contributions – Investigating high-frequency temporal data needs for GenCast models and extracting key climate variables for urban heatwave risk assessment."
    ],
    logo: gcriLogo
  },
  {
    title: "Senior Associate (L2)",
    company: "Innodata India Pvt. Ltd.",
    startDate: "November 2024",
    endDate: "December 2024",
    location: "Noida, India",
    description: [
      "Automated image-based Q&A generation using OpenAI and Anthropic APIs, boosting productivity by 300%.",
      "Designed and implemented LaTeX-based formatting pipelines and review processes, achieving 98% data accuracy.",
      "Streamlined Q&A generation, formatting, and review processes, reducing manual intervention by 80%."
    ],
    logo: innodataLogo
  },
  {
    title: "Research Intern",
    company: "National Institute of Technology Patna",
    startDate: "July 2023",
    endDate: "June 2024",
    location: "Patna, India",
    description: [
      "Engineered a ML-blockchain based healthcare fraud detection system with 99.93% accuracy.",
      "Implemented ECIES encryption with Hyperledger to ensure 99.9% protection of sensitive data.",
      "Developed a Flask web application integrating ML and blockchain for efficient fraud analysis."
    ],
    logo: nitPatnaLogo
  },
  {
    title: "Data Scientist Intern",
    company: "Arvo (OneArvo Ventures Pvt. Ltd.)",
    startDate: "March 2024",
    endDate: "May 2024",
    location: "Remote",
    description: [
      "Developed a deep learning model with transfer learning, enhancing detection accuracy by 15%.",
      "Created a synthetic data generation pipeline using GANs, expanding the dataset by 200%.",
      "Automated image processing to reduce manual inspection times by 20%, enhancing efficiency."
    ],
    logo: arvoLogo
  },
  {
    title: "Software Development Engineer Intern",
    company: "Eut Hub Technologies Pvt. Ltd.",
    startDate: "August 2023",
    endDate: "January 2024",
    location: "Remote",
    description: [
      "Developed 100+ Node.js data transformation functions with 450+ Jest tests, boosting BI data processing.",
      "Developed a React/Node.js chatbot with OpenAI models, boosting accuracy 20% and satisfaction 30%.",
      "Automated Google Sheets updates, reducing reporting time 25% and scaling BI via Snowflake/Qlik Sense."
    ],
    logo: eutHubLogo
  }
];

const Experience = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400 text-transparent bg-clip-text"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Professional Experience
        </motion.h2>

        <VerticalTimeline lineColor="rgba(59, 130, 246, 0.2)">
          {experiences.map((exp, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              contentStyle={{ 
                background: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                borderRadius: '16px',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)'
              }}
              contentArrowStyle={{ borderRight: '7px solid rgba(255, 255, 255, 0.1)' }}
              date={`${exp.startDate} - ${exp.endDate}`}
              dateClassName="text-gray-700 dark:text-gray-300"
              iconStyle={{ 
                background: 'rgba(255, 255, 255, 0.8)',
                boxShadow: '0 0 0 4px rgba(59, 130, 246, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden'
              }}
              icon={
                <img 
                  src={exp.logo}
                  alt={`${exp.company} logo`}
                  className="w-[80%] h-[80%] object-contain m-auto"
                />
              }
            >
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">{exp.title}</h3>
              <h4 className="text-xl font-semibold text-blue-600 dark:text-blue-400 mb-2">{exp.company}</h4>
              <p className="text-lg font-medium text-gray-600 dark:text-gray-400 mb-4">{exp.location}</p>
              
              <div className="space-y-2">
                <ul className="list-disc list-inside space-y-1">
                  {exp.description.map((item, i) => (
                    <li key={i} className="text-gray-700 dark:text-gray-300">{item}</li>
                  ))}
                </ul>
              </div>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </section>
  );
};

export default Experience;