import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaCoffee } from 'react-icons/fa';
import { useTheme } from '../context/ThemeContext';

const Footer = () => {
  const [isHovered, setIsHovered] = useState(false);
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  const name = "Dubasi Pavan Kumar";

  const quickLinks = [
    { name: 'LinkedIn', url: 'https://linkedin.com/in/im-pavankumar' },
    { name: 'GitHub', url: 'https://github.com/ascender1729' },
    { name: 'Twitter', url: 'https://twitter.com/pavankumardubasi' },
    { name: 'IEEE', url: 'https://ieeexplore.ieee.org/author/37089351225' },
    { name: 'ORCID', url: 'https://orcid.org/0000-0002-8774-3310' },
    { name: 'Google Scholar', url: 'https://scholar.google.com/citations?user=YOUR_ID' }
  ];

  const projects = [
    { name: 'VibeTensor', url: 'https://vibetensor.com/' },
    { name: 'DharaLink', url: 'https://dharalink.com/' },
    { name: 'TalentIQ 360', url: 'https://talentiq360.com/' }
  ];

  const contactInfo = [
    { name: 'Email', value: 'pavankumar.dubasi2019@gmail.com' },
    { name: 'Location', value: 'Hyderabad, India' }
  ];

  return (
    <footer className="w-full font-sans relative">
      <div className="max-w-6xl mx-auto py-12 px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-4">
          <div className="space-y-4">
            <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 via-blue-600 to-indigo-600 dark:from-indigo-400 dark:via-blue-400 dark:to-indigo-400">
              {name}
            </h2>
            <p className="text-sm text-slate-600 dark:text-slate-400">
              Full Stack Developer & AI Researcher
            </p>
            
            <a
              href="https://buymeacoffee.com/pavankumarm"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 px-3 sm:px-4 md:px-5 lg:px-6 py-1.5 sm:py-2 md:py-2 lg:py-2.5
                bg-[#FFDD00] hover:bg-[#FFDD00]/95
                rounded-lg sm:rounded-xl md:rounded-xl lg:rounded-2xl
                shadow-[2px_4px_16px_rgba(0,0,0,0.1),0_-1px_4px_rgba(0,0,0,0.02)]
                hover:shadow-[2px_6px_20px_rgba(0,0,0,0.12),0_-1px_8px_rgba(0,0,0,0.03)]
                border border-black/5
                transform before:absolute before:-inset-1 before:bg-gradient-to-b before:from-white/10 before:to-black/5 before:rounded-xl before:-z-10
                transition-all duration-300 ease-out
                group relative"
            >
              <FaCoffee className="w-[14px] h-[14px] sm:w-[15px] sm:h-[15px] md:w-[16px] md:h-[16px] lg:w-[17px] lg:h-[17px] text-black transform group-hover:rotate-12 transition-transform duration-300" />
              <span className="font-['Inter'] text-[11px] sm:text-[12px] md:text-[13px] lg:text-[14px] tracking-wide whitespace-nowrap font-medium text-black">
                Buy me a coffee
              </span>
            </a>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4 text-slate-700 dark:text-slate-300">
              Quick Links
            </h3>
            <div className="space-y-2">
              {quickLinks.map((link) => (
                <div key={link.name}>
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-slate-600 dark:text-slate-400 hover:text-indigo-600 dark:hover:text-indigo-400 
                      transition-all duration-300 block hover:translate-x-1 transform"
                  >
                    {link.name}
                  </a>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4 text-slate-700 dark:text-slate-300">
              Projects
            </h3>
            <div className="space-y-2">
              {projects.map((project) => (
                <div key={project.name}>
                  <a
                    href={project.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-slate-600 dark:text-slate-400 hover:text-indigo-600 dark:hover:text-indigo-400 
                      transition-all duration-300 block hover:translate-x-1 transform"
                  >
                    {project.name}
                  </a>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4 text-slate-700 dark:text-slate-300">
              Contact Me
            </h3>
            <div className="space-y-2">
              {contactInfo.map((info) => (
                <p key={info.name} className="text-slate-600 dark:text-slate-400">
                  <span className="font-medium">{info.name}:</span> {info.value}
                </p>
              ))}
            </div>
          </div>
        </div>

        <div className="border-t border-indigo-100/30 dark:border-indigo-900/30 my-2"></div>

        {/* Background Text Container */}
        <div className="relative min-h-[10rem] sm:min-h-[14rem] md:min-h-[18rem] lg:min-h-[22rem] overflow-hidden">
          {/* Built with love text */}
          <div className="max-w-6xl mx-auto px-4">
            <motion.div
              className="relative top-[15%] flex justify-center z-10"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              <div className={`inline-flex items-center px-3 sm:px-4 md:px-5 lg:px-6 py-1.5 sm:py-2 md:py-2 lg:py-2.5 rounded-lg sm:rounded-xl md:rounded-xl lg:rounded-2xl
                ${isDarkMode 
                  ? 'bg-[#E6E6E6] text-[#333333]' 
                  : 'bg-[#FFF4E6] text-[#4A3E35]'}
                shadow-[2px_4px_16px_rgba(0,0,0,0.1),0_-1px_4px_rgba(0,0,0,0.02)]
                hover:shadow-[2px_6px_20px_rgba(0,0,0,0.12),0_-1px_8px_rgba(0,0,0,0.03)]
                border border-black/5
                transform before:absolute before:-inset-1 before:bg-gradient-to-b before:from-white/10 before:to-black/5 before:rounded-xl before:-z-10
                transition-all duration-300`}
              >
                <span className="font-['Inter'] text-[11px] sm:text-[12px] md:text-[13px] lg:text-[14px] tracking-wide whitespace-nowrap font-medium">
                  Built with{' '}
                  <span className="inline-block mx-0.5 transform scale-100 sm:scale-110 md:scale-125 lg:scale-135 hover:scale-110 sm:hover:scale-125 md:hover:scale-140 lg:hover:scale-150 transition-transform duration-300">
                    <span className="text-red-500 not-italic filter drop-shadow-sm">❤️</span>
                  </span>
                  {' '}by:
                </span>
              </div>
            </motion.div>
          </div>

          {/* Large Background Text */}
          <div className="max-w-6xl mx-auto px-4">
            <motion.div
              className="w-full flex items-end justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              {/* Text with Enhanced Gradient */}
              <motion.h1
                className={`text-[8rem] sm:text-[12rem] md:text-[16rem] lg:text-[20rem] xl:text-[22rem] font-bold text-transparent bg-clip-text
                  relative w-full text-center -mb-4 mt-0
                  ${isDarkMode 
                    ? 'bg-gradient-to-t from-[#0099FF] via-[#0099FF] to-transparent'
                    : 'bg-gradient-to-t from-[#00B4FF] via-[#00B4FF] to-transparent'}
                  select-none pointer-events-none
                  font-['Montserrat',_sans-serif]
                  leading-[0.9]
                  bg-[length:100%_100%] bg-center bg-no-repeat`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              >
                Pavan
              </motion.h1>
            </motion.div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;