import React from 'react';
import { motion } from 'framer-motion';
import { FaFileAlt, FaBook, FaCertificate, FaUserPlus, FaExternalLinkAlt, FaGithub } from 'react-icons/fa';

const Research = () => {
  const thesis = {
    title: "Enhancing Healthcare Insurance Fraud Detection and Prevention with a Machine Learning and Blockchain-Based Approach",
    supervisor: "Dr. Rajesh Kumar Sinha, Associate Professor",
    department: "Department of Mathematics",
    institution: "National Institute of Technology Patna",
    period: "July, 2023 – June, 2024",
    abstract: "This research proposes an innovative approach to healthcare insurance fraud detection by combining machine learning algorithms with blockchain technology. The system achieves 99.93% accuracy in fraud detection while ensuring data integrity and privacy through blockchain implementation.",
    keywords: ["Healthcare Fraud", "Machine Learning", "Blockchain", "ECIES Encryption", "Smart Contracts", "Data Privacy"],
    codeLink: "https://github.com/ascender1729/HealthFraudMLChain",
    pdfLink: "https://drive.google.com/file/d/1xxnFXehxGAs7u633nSKRj6VNkeLY1JeJ/view?usp=sharing"
  };

  const publications = [
    {
      title: "Federated Learning for Privacy Preserving Collaboration in Healthcare",
      authors: "Dubasi Pavan Kumar, et al.",
      conference: "2025 International Conference on Communication, Computer Sciences and Engineering (IC3SE-2025)",
      details: "19-21 March 2025, Amity University Uttar Pradesh, Greater Noida, India. (Accepted)",
      abstract: "This paper presents a novel federated learning approach for healthcare data collaboration that preserves patient privacy while enabling effective model training across multiple healthcare institutions.",
      keywords: ["Federated Learning", "Healthcare", "Privacy", "Machine Learning"],
      status: "Accepted"
    },
    {
      title: "Artistic Style Transfer using Generative Adversarial Networks: A Pix2Pix Implementation",
      authors: "Dubasi Pavan Kumar, Bhadula, S., et al.",
      conference: "2024 International Conference on Communication, Computer Sciences and Engineering (IC3SE)",
      details: "Date of Conference: 09-11 May 2024, Date Added to IEEE Xplore: 23 July 2024",
      abstract: "We propose an enhanced Pix2Pix GAN architecture for artistic style transfer that achieves superior results in preserving structural integrity while transferring complex artistic styles.",
      keywords: ["GANs", "Style Transfer", "Deep Learning", "Computer Vision"],
      doi: "10.1109/IC3SE62002.2024.10592950",
      link: "https://ieeexplore.ieee.org/document/10592950"
    },
    {
      title: "A Study on Facial Feature Extraction and Emotional Analysis Using ML",
      authors: "Adhyaru, K., Ishan, Harshitha, D., Mundlur, R.P., Dubali Pavan Kumar, et al.",
      journal: "International Journal of All Research Education and Scientific Methods (IJARESM)",
      details: "ISSN: 2455-6211, Vol. 11, Issue 7, July 2023, pp. 2455-2467",
      abstract: "This research presents a comprehensive approach to facial feature extraction and emotion recognition using machine learning techniques, achieving high accuracy in real-world scenarios.",
      keywords: ["Facial Recognition", "Emotion Analysis", "Machine Learning", "Computer Vision"],
      link: "https://www.ijaresm.com/a-study-on-facial-feature-extraction-and-emotional-analysis-using-ml"
    }
  ];

  const patents = [
    {
      title: "AI-Enabled Robotic Device for Business Automation",
      inventors: "Kousar, H., Dubasi Pavan Kumar, Mohammed, S.A., et al.",
      details: "UK Design Registration No. 6335588, Grant Date: 10 January 2024",
      abstract: "An innovative robotic system that leverages AI for automating complex business processes, improving efficiency and reducing operational costs.",
      status: "Granted",
      link: "https://www.registered-design.service.gov.uk/find/6335588"
    },
    {
      title: "Emotion Recognition and Analysis System for Mental Health Assessment",
      inventors: "Navaneethakrishan, S.R., Chinta, S., Dubasi Pavan Kumar, et al.",
      details: "The Patent Office Journal, 29/09/2023, No. 39/2023, pp. 64318-64802",
      abstract: "A novel system for real-time emotion recognition and analysis, specifically designed for mental health monitoring and assessment.",
      status: "Published",
      link: "https://www.search.ipindia.gov.in/IPOJournal/Journal/Patent"
    }
  ];

  const memberships = [
    {
      organization: "International Association of Engineers (IAENG)",
      id: "383517",
      period: "Aug 2024 - Present"
    },
    {
      organization: "American Mathematical Society (AMS)",
      id: "PVDBXA",
      period: "Aug 2024 - Present"
    },
    {
      organization: "Association for Computing Machinery (ACM)",
      id: "8485149",
      period: "Aug 2024 - Present"
    },
    {
      organization: "Institute of Electrical and Electronics Engineers (IEEE)",
      id: "98915358",
      period: "Jan 2024 - Present"
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <section className="py-20">
      <div className="container mx-auto px-4 max-w-6xl">
        <motion.h2 
          className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400 text-transparent bg-clip-text"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Research & Publications
        </motion.h2>
        
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="space-y-12"
        >
          {/* Master's Thesis Section */}
          <motion.div
            variants={itemVariants}
            className="ai-card hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-2xl font-bold ai-gradient-text">Master's Thesis</h3>
              <FaBook className="text-4xl text-blue-600 dark:text-blue-400" />
            </div>
            <div className="space-y-4">
              <h4 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                {thesis.title}
              </h4>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-600 dark:text-gray-400">
                <div>
                  <p><strong>Supervisor:</strong> {thesis.supervisor}</p>
                  <p><strong>Department:</strong> {thesis.department}</p>
                  <p><strong>Institution:</strong> {thesis.institution}</p>
                  <p><strong>Period:</strong> {thesis.period}</p>
                </div>
                <div>
                  <p className="font-medium mb-2">Abstract:</p>
                  <p className="text-sm">{thesis.abstract}</p>
                </div>
              </div>
              <div className="flex flex-wrap gap-2 mt-4">
                {thesis.keywords.map((keyword, index) => (
                  <span key={index} className="ai-tag">{keyword}</span>
                ))}
              </div>
              <div className="flex flex-col sm:flex-row gap-4 mt-6">
                <a 
                  href={thesis.pdfLink} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="ai-button group flex items-center justify-center w-full sm:w-auto"
                >
                  <FaFileAlt className="mr-2" />
                  <span>View Thesis</span>
                  <FaExternalLinkAlt className="ml-2 group-hover:translate-x-1 transition-transform" />
                </a>
                <a 
                  href={thesis.codeLink} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="ai-button-secondary group flex items-center justify-center w-full sm:w-auto"
                >
                  <FaGithub className="mr-2" />
                  <span>View Code</span>
                  <FaExternalLinkAlt className="ml-2 group-hover:translate-x-1 transition-transform" />
                </a>
              </div>
            </div>
          </motion.div>

          {/* Publications Section */}
          <motion.div
            variants={itemVariants}
            className="ai-card hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-2xl font-bold ai-gradient-text">Publications</h3>
              <FaFileAlt className="text-4xl text-blue-600 dark:text-blue-400" />
            </div>
            <div className="space-y-8">
              {publications.map((pub, index) => (
                <div 
                  key={index} 
                  className="border-b border-gray-200 dark:border-gray-700 last:border-0 pb-8 last:pb-0"
                >
                  <h4 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-3">
                    {pub.title}
                  </h4>
                  <p className="text-gray-600 dark:text-gray-400 mb-2">
                    <strong>Authors:</strong> {pub.authors}
                  </p>
                  <p className="text-gray-600 dark:text-gray-400 mb-2">
                    {pub.conference || pub.journal}
                  </p>
                  <p className="text-gray-600 dark:text-gray-400 mb-4">
                    {pub.details}
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
                    <strong>Abstract:</strong> {pub.abstract}
                  </p>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {pub.keywords.map((keyword, kidx) => (
                      <span key={kidx} className="ai-tag">{keyword}</span>
                    ))}
                  </div>
                  {pub.link && (
                    <a 
                      href={pub.link} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="ai-button group inline-flex items-center"
                    >
                      <span>View Publication</span>
                      <FaExternalLinkAlt className="ml-2 group-hover:translate-x-1 transition-transform" />
                    </a>
                  )}
                </div>
              ))}
            </div>
          </motion.div>

          {/* Patents Section */}
          <motion.div
            variants={itemVariants}
            className="ai-card hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-2xl font-bold ai-gradient-text">Patents</h3>
              <FaCertificate className="text-4xl text-blue-600 dark:text-blue-400" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {patents.map((patent, index) => (
                <div 
                  key={index} 
                  className="border border-gray-200 dark:border-gray-700 rounded-xl p-6"
                >
                  <div className="flex items-start justify-between mb-4">
                    <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                      {patent.title}
                    </h4>
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                      patent.status === 'Granted' 
                        ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                        : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
                    }`}>
                      {patent.status}
                    </span>
                  </div>
                  <p className="text-sm text-gray-600 dark:text-gray-400 mb-3">
                    <strong>Inventors:</strong> {patent.inventors}
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400 mb-3">
                    {patent.details}
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
                    {patent.abstract}
                  </p>
                  <a 
                    href={patent.link} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="ai-button group inline-flex items-center"
                  >
                    <span>View Patent</span>
                    <FaExternalLinkAlt className="ml-2 group-hover:translate-x-1 transition-transform" />
                  </a>
                </div>
              ))}
            </div>
          </motion.div>

          {/* Professional Memberships Section */}
          <motion.div
            variants={itemVariants}
            className="ai-card hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-2xl font-bold ai-gradient-text">Professional Memberships</h3>
              <FaUserPlus className="text-4xl text-blue-600 dark:text-blue-400" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {memberships.map((membership, index) => (
                <div 
                  key={index} 
                  className="border border-gray-200 dark:border-gray-700 rounded-xl p-6"
                >
                  <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2">
                    {membership.organization}
                  </h4>
                  <p className="text-gray-600 dark:text-gray-400 mb-2">
                    Membership ID: {membership.id}
                  </p>
                  <p className="text-gray-600 dark:text-gray-400">
                    {membership.period}
                  </p>
                </div>
              ))}
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default Research;