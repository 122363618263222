import React from 'react';
import { motion } from 'framer-motion';
import { FaDownload, FaArrowRight, FaGithub, FaLinkedin, FaXTwitter, FaGraduationCap } from 'react-icons/fa6';
import { SiIeee, SiOrcid } from 'react-icons/si';
import profileImage from '../images/PHOTO.jpg';

const About = ({ setCurrentSection }) => {
  const cvLink = "https://bit.ly/resumePavan";

  const quote = {
    text: "The two most important days in your life are the day you are born and the day you find out why.",
    author: "Mark Twain"
  };

  const skills = [
    {
      category: "Research",
      items: ["Machine Learning", "Deep Learning", "Computer Vision", "Natural Language Processing", "Quantum Computing", "Data Science"]
    },
    {
      category: "Development",
      items: ["Full Stack Development", "API Design", "Cloud Architecture", "DevOps", "System Design", "Database Management"]
    },
    {
      category: "Mathematics",
      items: ["Linear Algebra", "Probability Theory", "Statistical Analysis", "Optimization", "Graph Theory", "Number Theory"]
    }
  ];

  const achievements = [
    {
      title: "Publications",
      count: "3",
      description: "Research papers in leading journals"
    },
    {
      title: "Patents",
      count: "2",
      description: "Filed and granted patents"
    },
    {
      title: "Projects",
      count: "30+",
      description: "Successful project completions"
    },
    {
      title: "Students",
      count: "300+",
      description: "Mentored in tech & research"
    }
  ];

  return (
    <section className="py-20">
      <div className="container mx-auto px-4 max-w-6xl">
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <blockquote className="text-2xl italic text-gray-700 dark:text-gray-300 mb-12">
            "{quote.text}"
            <footer className="mt-4 text-lg text-gray-600 dark:text-gray-400">
              — {quote.author}
            </footer>
          </blockquote>

          <h1 className="text-4xl md:text-5xl font-bold mb-6 ai-gradient-text">About Me</h1>
          <p className="text-xl md:text-2xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Bridging the gap between theoretical mathematics and practical AI solutions
          </p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
          {/* Left Column - Image and Social Links */}
          <motion.div
            className="lg:col-span-4"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <div className="sticky top-24">
              <div className="relative rounded-2xl overflow-hidden shadow-2xl mb-8">
                <img 
                  src={profileImage} 
                  alt="Dubasi Pavan Kumar" 
                  className="w-full h-auto"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
              </div>

              <div className="flex justify-center gap-3 mb-8 flex-wrap">
                <a
                  href="https://github.com/ascender1729"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ai-button-circle group"
                  title="GitHub"
                >
                  <FaGithub className="w-5 h-5 group-hover:scale-110 transition-transform" />
                </a>
                <a
                  href="https://linkedin.com/in/im-pavankumar"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ai-button-circle group"
                  title="LinkedIn"
                >
                  <FaLinkedin className="w-5 h-5 group-hover:scale-110 transition-transform" />
                </a>
                <a
                  href="https://twitter.com/the_complex_one"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ai-button-circle group"
                  title="Twitter"
                >
                  <FaXTwitter className="w-5 h-5 group-hover:scale-110 transition-transform" />
                </a>
                <a
                  href="https://ieee-collabratec.ieee.org/app/p/pavankumar"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ai-button-circle group"
                  title="IEEE Collabratec"
                >
                  <SiIeee className="w-5 h-5 group-hover:scale-110 transition-transform" />
                </a>
                <a
                  href="https://orcid.org/0009-0006-1060-4598"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ai-button-circle group"
                  title="ORCID"
                >
                  <SiOrcid className="w-5 h-5 group-hover:scale-110 transition-transform" />
                </a>
                <a
                  href="https://scholar.google.com/citations?user=0hGebsQAAAAJ"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ai-button-circle group"
                  title="Google Scholar"
                >
                  <FaGraduationCap className="w-5 h-5 group-hover:scale-110 transition-transform" />
                </a>
              </div>

              <div className="flex flex-col gap-4">
                <a 
                  href={cvLink} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="ai-button group w-full flex items-center justify-center transform hover:scale-105 transition-all duration-300"
                >
                  <FaDownload className="mr-2 group-hover:scale-110 transition-transform" />
                  <span className="group-hover:tracking-wider transition-all">Download CV</span>
                </a>
                <button
                  onClick={() => setCurrentSection('contact')}
                  className="ai-button-secondary group w-full flex items-center justify-center transform hover:scale-105 transition-all duration-300"
                >
                  <span className="group-hover:tracking-wider transition-all">Contact Me</span>
                  <FaArrowRight className="ml-2 group-hover:translate-x-1 group-hover:scale-110 transition-all" />
                </button>
              </div>
            </div>
          </motion.div>

          {/* Right Column - Content */}
          <motion.div
            className="lg:col-span-8 space-y-12"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            {/* Bio Section */}
            <div className="ai-card">
              <h2 className="text-2xl font-bold mb-6 ai-gradient-text">Biography</h2>
              <div className="space-y-4 text-gray-600 dark:text-gray-300">
                <p>
                  I'm Dubasi Pavan Kumar, an aspiring researcher at the intersection of mathematics and artificial intelligence. I have completed my Integrated Master of Science in Mathematics at the National Institute of Technology, Patna, maintaining an impressive CGPA of 8.89/10, reflecting my dedication to academic excellence.
                </p>
                <p>
                  My research interests span machine learning, data science, and quantum computing. I'm particularly passionate about developing unbiased AI solutions that can make a positive impact on society. Through my work, I aim to bridge the gap between theoretical mathematics and practical applications in technology.
                </p>
                <p>
                  Beyond academics, I'm actively involved in various research projects and have contributed to several publications in the field of AI and machine learning. I believe in the power of continuous learning and staying at the forefront of technological advancements.
                </p>
              </div>
            </div>

            {/* Skills Section */}
            <div className="ai-card">
              <h2 className="text-2xl font-bold mb-6 ai-gradient-text">Expertise</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {skills.map((skillSet, index) => (
                  <div key={index}>
                    <h3 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
                      {skillSet.category}
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {skillSet.items.map((skill, skillIndex) => (
                        <span key={skillIndex} className="ai-tag">
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Achievements Section */}
            <div className="ai-card">
              <h2 className="text-2xl font-bold mb-6 ai-gradient-text">Achievements</h2>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                {achievements.map((achievement, index) => (
                  <div key={index} className="text-center">
                    <div className="text-3xl font-bold ai-gradient-text mb-2">
                      {achievement.count}
                    </div>
                    <div className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-1">
                      {achievement.title}
                    </div>
                    <div className="text-sm text-gray-600 dark:text-gray-400">
                      {achievement.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Education Section */}
            <div className="ai-card">
              <h2 className="text-2xl font-bold mb-6 ai-gradient-text">Recent Education</h2>
              <div className="space-y-2">
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  Integrated MSc in Mathematics
                </h3>
                <p className="text-gray-600 dark:text-gray-400">
                  National Institute of Technology Patna
                </p>
                <p className="text-gray-600 dark:text-gray-400">
                  2019 - 2024 • CGPA: 8.89/10
                </p>
                <div className="flex flex-wrap gap-2 mt-4">
                  <span className="ai-tag">Department's Highest CGPA</span>
                  <span className="ai-tag">Research Publication in IEEE</span>
                  <span className="ai-tag">Best Final Year Project</span>
                  <span className="ai-tag">Mathematics Department Representative</span>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;