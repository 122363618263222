import React, { useEffect, useState } from 'react';
import AnimatedCursor from 'react-animated-cursor';
import { isMobile, isTablet } from 'react-device-detect';

const AnimatedCursorComponent = ({ children }) => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsDesktop(!isMobile && !isTablet);
  }, []);

  return (
    <>
      {isDesktop && (
        <AnimatedCursor
          innerSize={8}
          outerSize={35}
          innerScale={1}
          outerScale={2}
          outerAlpha={0}
          hasBlendMode={true}
          innerStyle={{
            backgroundColor: 'var(--cursor-color, #6366F1)',
            mixBlendMode: 'difference'
          }}
          outerStyle={{
            border: '3px solid var(--cursor-color, #6366F1)',
            mixBlendMode: 'difference'
          }}
          clickables={[
            'a',
            'button',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'label[for]',
            'select',
            'textarea',
            '.link',
            '.clickable'
          ]}
        />
      )}
      {children}
    </>
  );
};

export default AnimatedCursorComponent;